<template>
    <div class="single-sect-wrap" :style="{'background-color': sectData.backgorund}">
        <div class="single-sect-content">
            <img class="desktop" :src="sectData.desktop" alt="">
            <img class="mobile" :src="sectData.mobile" alt="">
        </div>
    </div>
</template>
<script>
export default {
    props: {
    sectData: {
      type: Object,
    },
  },
}
</script>
<style scoped lang="scss">
.single-sect-wrap{
    .single-sect-content{
        max-width: 1920px;
        margin: 0 auto;
        .desktop{
            max-width: 100%;
            display: block;
        }
        .mobile{
            display: none;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 700px) {
    .single-sect-wrap{
        .single-sect-content{
            .desktop{
                display: none;
            }
            .mobile{
                display: block;
            }
        }
    }
}

</style>