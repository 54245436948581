<template>
  <div class="admin-panel">
    <div class="wrapper">
      <AdminPanelNav
        @searchResults="handleSearchResults"
        @filter="handleFilter"
        @sent="handleSent"
      />
      <PaginationComponent
        :total="total"
        @offset="reloadWithOffset"
        @refreshWithFilter="refreshWithFilter"
        @resetPagination="handleResetPagination"
        :resetPaginationBool="resetPaginationBool"
      />
      <div class="table-wrapper" v-if="!showConfirmModal">
        <SingleTableRow
          :ticketData="tableHeading"
          :hideUnnecessaryData="hideUnnecessaryData"
          :gifts="gifts"
        />
        <div v-for="ticket in ticketArr" :key="ticket.frm_id">
          <SingleTableRow
            :ticketData="ticket"
            :hideUnnecessaryData="hideUnnecessaryData"
            :gifts="gifts"
            @showModal="handleShowModal"
            @passTicketData="handlePassTicketData"
          />
        </div>
        <HalfScreenModal
          v-if="hideUnnecessaryData"
          :ticketData="ticketData"
          @showConfirmBool="handleShowConfirmBool"
          @refresh="refreshWithFilter"
          @closeModal="hideUnnecessaryData=false"
        />
      </div>
      <ConfirmationModal
        v-if="showConfirmModal"
        @closeModal="handleCloseModal"
        :apiData="apiData"
      />
    </div>
    <div class="excel-wrap">
      <button :class="{ excelDownloadOnOpenModal : hideUnnecessaryData }" @click="getCsvData">{{ $t('adminPanel.downloadExcel') }}</button>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapState, mapActions } from 'vuex'
import AdminPanelNav from '../components/AdminPanelComponents/AdminPanelNav.vue'
import PaginationComponent from '../components/AdminPanelComponents/PaginationComponent.vue'
import SingleTableRow from '../components/AdminPanelComponents/SingleTableRow.vue'
import HalfScreenModal from '../components/AdminPanelComponents/HalfScreenModal.vue'
import ConfirmationModal from '../components/AdminPanelComponents/ConfirmationModal.vue'
export default {
  components: {
    AdminPanelNav,
    PaginationComponent,
    SingleTableRow,
    HalfScreenModal,
    ConfirmationModal,
  },
  data() {
    return {
      ticketArr: [],
      ticketArrReplica: [],
      ticketArrReplicaConfirmed: [],
      filter: '',
      tableHeading: {
        frm_id: this.$t('tableHeader.id'),
        frm_name: this.$t('tableHeader.name'),
        frm_lastname: this.$t('tableHeader.lastName'),
        frm_address: this.$t('tableHeader.address'),
        frm_place: this.$t('tableHeader.city'),
        frm_gift: this.$t('tableHeader.gift'),
        frm_phone: this.$t('tableHeader.phone'),
        imi_imei: this.$t('tableHeader.imei'),
        isHeading: true,
      },
      hideUnnecessaryData: false,
      ticketData: {},
      showConfirmModal: false,
      refreshCheckbox: null,
      apiData: {},
      total: 0,
      hasString: null,
      resetPaginationBool: false,
      stringToSearch: '',
      isDelivered: null,
      offset: null,
      csvData: [],
      gifts: [],
    }
  },
  computed: {
    ...mapState(['sid']),
  },
  mounted() {
    this.checkSession()
    this.init()
    this.getSubmissions()
  },
  watch: {
    isDelivered(val) {
      console.log(val)
      this.handleResetPagination()
    },
  },
  methods: {
    ...mapActions(['checkSession']),
    filtering() {},
    handleSent(payload) {
      this.offset = 0
      this.isDelivered = payload
      this.handleFilter()
      // this.isDelivered = null
    },
    async init() {
      await this.getGifts()
    },
    async getGifts() {
      try {
        const res = await api.getGifts()
        this.gifts = res.data.data
        console.log(this.gifts)
      } catch (error) {
        console.log(error)
      }
    },
    // OVDE RADIMO
    async handleFilter(filter) {
      if (this.filter != 'confirmed') this.isDelivered = null
      const str = this.stringToSearch ? this.stringToSearch : null
      let offset

      if (filter) {
        this.filter = filter
        this.hideUnnecessaryData = false
        this.handleResetPagination()
        this.offset = 0
        this.isDelivered = null
        console.log(this.isDelivered)
      }
      if (this.offset == 0 && !this.offset) {
        offset = null
      } else {
        offset = this.offset
      }
      if (this.filter == 'all') {
        this.getSubmissions(offset, str, null, this.isDelivered)
      }
      if (this.filter == 'unconfirmed') {
        this.getSubmissions(offset, str, 'null', this.isDelivered)
      }
      if (this.filter == 'refused') {
        this.getSubmissions(offset, str, '0', this.isDelivered)
      }
      if (this.filter == 'confirmed') {
        this.getSubmissions(offset, str, '1', this.isDelivered)
      }
    },
    handleResetPagination(bool) {
      this.offset = 0
      this.resetPaginationBool = bool
    },
    refreshWithFilter() {
      this.handleFilter()
    },
    async reloadWithOffset(offset) {
      // await this.getSubmissions(offset);
      // if (this.filter == "all") {
      //   return;
      // }
      this.offset = offset
      console.log(this.offset)
      this.handleFilter()
    },
    handleShowModal() {
      this.hideUnnecessaryData = true
    },
    handlePassTicketData(ticketData) {
      this.ticketData = ticketData
    },
    handleShowConfirmBool(payload) {
      this.showConfirmModal = true
      this.apiData = payload
      this.apiData.sid = this.sid
        ? this.sid
        : JSON.parse(localStorage.getItem('sid'))
    },
    handleCloseModal() {
      this.showConfirmModal = false
      this.refreshWithFilter()
    },
    async handleSearchResults(payload) {
      this.stringToSearch = payload.string
      this.offset = 0
      this.handleFilter(payload.filter)
    },
    async getSubmissions(offset, search, approvalStatus, deliveryStatus) {
      const sid = this.sid ? this.sid : JSON.parse(localStorage.getItem('sid'))
      try {
        const res = await api.getSubmissions({
          sid: sid,
          offset: offset,
          search: search,
          approval_status: approvalStatus,
          delivery_status: deliveryStatus,
        })
        this.ticketArr = res.data.data
        console.log(this.ticketArr);
        this.total = res.data.total
      } catch (error) {
        console.log(error)
      }
    },
    async getCsvData() {
      this.deliveryStatus = this.isDelivered;
      const sid = this.sid ? this.sid : JSON.parse(localStorage.getItem('sid'))
      const params = {
        sid: sid,
      }
      if (this.filter == "unconfirmed") {
        params.approval_status = "null";
        params.delivery_status = "null";
      }
      if (this.filter == "refused") {
        params.approval_status = 0;
      }
      if (this.filter == "confirmed") {
        console.log(this.deliveryStatus);
        console.log(this.isDelivered);
        params.approval_status = 1;
        if (this.deliveryStatus == "null") params.delivery_status = "null";
        if (this.deliveryStatus == 1) params.delivery_status = 1;
      }
      // if (this.deliveryStatus == 1) params.delivery_status = 1
      // if (this.deliveryStatus == null) params.delivery_status = 'null'
      // if (this.filter == 'confirmed') params.approval_status = 1
      console.log(params)
      try {
        const res = await api.getCsvData(params)
        this.csvData = res.data
        const blob = new Blob([this.csvData], { type: 'application/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Xiaomi_K3S.csv')
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-panel {
  position: relative;
  height: 100%;
  padding: 0 0 50px 0;
  .wrapper {
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    .table-wrapper {
      position: relative;
    }
  }
  .excel-wrap{
    margin: 0 auto;
    max-width: 1920px;
    button {
      display: block;
      background-color: rgb(238, 131, 54);
      color: rgb(239, 236, 233);
      width: 150px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: Xiaomi-Regular;
      border: none;
      outline: none;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      margin: 50px auto 0 auto;
    }
    .excelDownloadOnOpenModal{
      margin: 50px 72% auto auto
    }
  }
}
</style>
