<template>
    <div class="section-wrap">
        <ImageSection v-for="(section, index) in sections" :key="index" :sectData="section"></ImageSection>
    </div>
</template>
<script>
import ImageSection from "../components/ImageSection.vue"

import Section1Desk from "../assets/sections/sec1desk.png"
import Section1Mob from "../assets/sections/sec1mob.png"
import Section2Desk from "../assets/sections/sec2desk.png"
import Section2Mob from "../assets/sections/sec2mob.png"
import Section3Desk from "../assets/sections/sec3desk.png"
import Section3Mob from "../assets/sections/sec3mob.png"
import Section4Desk from "../assets/sections/sec4desk.png"
import Section4Mob from "../assets/sections/sec4mob.png"
import Section5Desk from "../assets/sections/sec5desk.png"
import Section5Mob from "../assets/sections/sec5mob.png"
import Section6Desk from "../assets/sections/sec6desk.png"
import Section6Mob from "../assets/sections/sec6mob.png"
import Section7Desk from "../assets/sections/sec7desk.png"
import Section7Mob from "../assets/sections/sec7mob.png"
import Section8Desk from "../assets/sections/sec8desk.png"
import Section8Mob from "../assets/sections/sec8mob.png"
import Section9Desk from "../assets/sections/sec9desk.png"
import Section9Mob from "../assets/sections/sec9mob.png"
import Section10Desk from "../assets/sections/sec10desk.png"
import Section10Mob from "../assets/sections/sec10mob.png"
import Section11Desk from "../assets/sections/sec11desk.png"
import Section11Mob from "../assets/sections/sec11mob.png"
import Section12Desk from "../assets/sections/sec12desk.png"
import Section12Mob from "../assets/sections/sec12mob.png"
import Section13Desk from "../assets/sections/sec13desk.png"
import Section13Mob from "../assets/sections/sec13mob.png"
import Section13_1Desk from "../assets/sections/sec13_1desk.png"
import Section13_1Mob from "../assets/sections/sec13_1mob.png"
import Section14Desk from "../assets/sections/sec14desk.png"
import Section14Mob from "../assets/sections/sec14mob.png"
import Section15Desk from "../assets/sections/sec15desk.png"
import Section15Mob from "../assets/sections/sec15mob.png"
import Section16Desk from "../assets/sections/sec16desk.png"
import Section16Mob from "../assets/sections/sec16mob.png"
import Section17Desk from "../assets/sections/sec17desk.png"
import Section17Mob from "../assets/sections/sec17mob.png"
import Section18Desk from "../assets/sections/sec18desk.png"
import Section18Mob from "../assets/sections/sec18mob.png"

export default {
  components: {
    ImageSection,
  },
  data() {
    return {
      sections: [
        {
            desktop: Section1Desk,
            mobile: Section1Mob,
            backgorund: '#161415',
        },
        {
            desktop: Section2Desk,
            mobile: Section2Mob,
        },
        {
            desktop: Section3Desk,
            mobile: Section3Mob,
        },
        {
            desktop: Section4Desk,
            mobile: Section4Mob,
        },
        {
            desktop: Section5Desk,
            mobile: Section5Mob,
        },
        {
            desktop: Section6Desk,
            mobile: Section6Mob,
        },
        {
            desktop: Section7Desk,
            mobile: Section7Mob,
        },
        {
            desktop: Section8Desk,
            mobile: Section8Mob,
        },
        {
            desktop: Section9Desk,
            mobile: Section9Mob,
            backgorund: "#060606",
        },
        {
            desktop: Section10Desk,
            mobile: Section10Mob,
        },
        {
            desktop: Section11Desk,
            mobile: Section11Mob,
        },
        {
            desktop: Section12Desk,
            mobile: Section12Mob,
        },
        {
            desktop: Section13Desk,
            mobile: Section13Mob,
        },
        {
            desktop: Section13_1Desk,
            mobile: Section13_1Mob,
            backgorund: '#161515',
        },
        {
            desktop: Section14Desk,
            mobile: Section14Mob,
            backgorund: "#050505",
        },
        {
            desktop: Section15Desk,
            mobile: Section15Mob,
        },
        {
            desktop: Section16Desk,
            mobile: Section16Mob,
            backgorund: '#151314',
        },
        {
            desktop: Section17Desk,
            mobile: Section17Mob,
        },
        {
            desktop: Section18Desk,
            mobile: Section18Mob,
        },
      ],
    };
  },
}
</script>
<style scoped lang="scss">
.section-wrap {
  background-color: #000;
}
</style>